import React from 'react';
import useStyles from './Features.styles';
import { Container, Grid } from '@material-ui/core';
import automaticIcon from '../../../../../content/assets/icons/API/Automatic.svg';
import secureIcon from '../../../../../content/assets/icons/API/Secure.svg';
import fastIcon from '../../../../../content/assets/icons/API/Fast.svg';

const Features = () => {
  const classes = useStyles();
  return (
    <section id="" className={classes.root}>
      <Container className={classes.container} maxWidth="lg">
        <Grid container justifyContent="space-between">
          <Grid className={classes.item} item>
            <img src={automaticIcon} alt="Automática" />
            <h2>Automática</h2>
            <p>
              Nuestra API te da la posibilidad de
              <strong> integrar los sistemas de tu empresa </strong>con nuestros servicios y así
              realizar pagos directamente desde estos.
            </p>
          </Grid>
          <Grid className={classes.item} item>
            <img src={secureIcon} alt="Seguro" />
            <h2>Segura</h2>
            <p>
              Incorporamos los mejores<strong> estándares de seguridad </strong>
              con el fin de cuidar el acceso y la confidencialidad del servicio.
            </p>
            <p>
              Utilizamos el<strong> estándar OAuth </strong>para el control de acceso y una capa de
              transporte<strong> TLS </strong>para cifrar las peticiones.
            </p>
          </Grid>
          <Grid className={classes.item} item>
            <img src={fastIcon} alt="Rápido" />
            <h2>Rápida</h2>
            <p>
              Integración<strong> “Plug and Play” </strong>y ambiente de prueba
              <strong> Sandbox </strong>para que puedas simular rápidamente la integración con
              nuestra API.
            </p>
          </Grid>
        </Grid>
      </Container>
    </section>
  );
};
export default Features;
