import React from 'react';
import { graphql, PageProps } from 'gatsby';
import API from '../components/Landing/API/API';
import SEO from '../components/SEO';

const PAGE_TITLE = 'CurrencyBird API: Soluciones Automatizadas para Pagos Internacionales';
const PAGE_DESCRIPTION =
  'Automatiza tus pagos globales con la API de CurrencyBird: segura y rápida. Ideal para empresas que buscan integración fácil y acceso a divisas.';

const APIPage: React.FC<PageProps> = () => {
  return (
    <>
      <SEO title={PAGE_TITLE} description={PAGE_DESCRIPTION} withTemplate={false} />
      <API />
    </>
  );
};

export default APIPage;

export const pageQuery = graphql`
  query APIPage {
    site {
      siteMetadata {
        title
      }
    }
  }
`;
