import React from 'react';
import { CircularProgress, Grid, IconButton } from '@material-ui/core';
import Alert from '../Common/Alert';
import Button from '@material-ui/core/Button';
import TextField from '@material-ui/core/TextField';
import Dialog from '@material-ui/core/Dialog';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
import useStyles from './ContactForm.styles';
import CloseIcon from '@material-ui/icons/Close';
import { useFormik } from 'formik';
import APISendEmail from '../../services/APISendEmail.service';
import ReCAPTCHA from 'react-google-recaptcha';
import * as yup from 'yup';

const requiredText = '*Campo obligatorio';
const validationSchema = yup.object({
  names: yup
    .string()
    .max(60, '*Excede el máximo de caracteres.')
    .matches(/^[a-zA-Z_ \u00E0-\u00FC]+$/, '*Caracteres especiales no permitidos.')
    .required(requiredText),
  lastNames: yup
    .string()
    .max(60, '*Excede el máximo de caracteres.')
    .matches(/^[a-zA-Z_ \u00E0-\u00FC]+$/, '*Caracteres especiales no permitidos.')
    .required(requiredText),
  email: yup
    .string()
    .max(60, '*Excede el máximo de caracteres.')
    .email('*Ingresa un email válido')
    .required(requiredText),
  enterprise: yup
    .string()
    .max(60, '*Excede el máximo de caracteres.')
    .matches(/^[a-zA-Z0-9_ \u00E0-\u00FC]+$/, '*Caracteres especiales no permitidos.')
    .required(requiredText),
  phoneNumber: yup.string().max(12, '*Número excede el máximo').required(requiredText),
  comment: yup
    .string()
    .matches(/^((?!https)(?!http)(?!ftp)(?!:\/\/).)*$/i, {
      message: '*URLs no permitidas',
      excludeEmptyString: true,
    })
    .max(300, '*Número excede el máximo'),
});

const sleep = (time: number) => new Promise((acc) => setTimeout(acc, time));

const formTeo = () => {
  const formik = useFormik({
    initialValues: {
      names: '',
      lastNames: '',
      enterprise: '',
      phoneNumber: '',
      email: '',
      comment: '',
    },
    validationSchema: validationSchema,
    onSubmit: async (values) => {
      await APISendEmail.post('', values)
        .then((response) => {
          setAlertOpen(true);
          setResponseMsg('Se ha enviado su información correctamente.');
          setAlertTitle('Formulario enviado.');
          setAlertType('success');
          formik.resetForm();
          handleClose();
        })
        .catch((error) => {
          setAlertOpen(true);
          setAlertTitle('Error.');
          setResponseMsg('Ha ocurrido un error, intenta nuevamente.');
          setAlertType('error');
          handleClose();
        });
    },
  });
  const [open, setOpen] = React.useState(false);

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    formik.resetForm();
    setOpen(false);
  };
  const [alertOpen, setAlertOpen] = React.useState(false);
  const [alertTitle, setAlertTitle] = React.useState('');
  const [alertType, setAlertType] = React.useState<
    'success' | 'info' | 'warning' | 'error' | undefined
  >('error');
  const [responseMsg, setResponseMsg] = React.useState('');
  const [captchaValue, setCaptchaValue] = React.useState('');

  const recaptchaRef = React.createRef();
  const classes = useStyles();
  return (
    <React.Fragment>
      <div style={{ padding: '20px 0' }}>
        {alertOpen && (
          <Alert
            setAlertOpen={setAlertOpen}
            open={alertOpen}
            title={alertTitle}
            description={responseMsg}
            severity={alertType}
          />
        )}
      </div>
      <div className={classes.buttonContainer}>
        <Button className={classes.contactButton} onClick={handleClickOpen}>
          Contáctanos
        </Button>
      </div>

      <Dialog
        open={open}
        onClose={handleClose}
        aria-labelledby="form-dialog-title"
        className={classes.dialog}
        maxWidth="md"
      >
        <DialogTitle id="form-dialog-title">
          Contáctanos
          <IconButton aria-label="close" className={classes.closeButton} onClick={handleClose}>
            <CloseIcon />
          </IconButton>
        </DialogTitle>
        <DialogContent>
          <form autoComplete="off" className={classes.form} onSubmit={formik.handleSubmit}>
            <Grid container spacing={2}>
              <Grid item xs={12} md={6}>
                <TextField
                  autoComplete="off"
                  autoFocus
                  error={formik.touched.names && Boolean(formik.errors.names)}
                  fullWidth
                  helperText={formik.touched.names && formik.errors.names}
                  label="Nombres"
                  margin="dense"
                  name="names"
                  onBlur={formik.handleBlur}
                  onChange={formik.handleChange}
                  required
                  variant="outlined"
                  value={formik.values.names}
                />
              </Grid>
              <Grid item xs={12} md={6}>
                <TextField
                  margin="dense"
                  name="lastNames"
                  label="Apellidos"
                  value={formik.values.lastNames}
                  onBlur={formik.handleBlur}
                  onChange={formik.handleChange}
                  fullWidth
                  autoComplete="off"
                  variant="outlined"
                  required
                  error={formik.touched.lastNames && Boolean(formik.errors.lastNames)}
                  helperText={formik.touched.lastNames && formik.errors.lastNames}
                />
              </Grid>
              <Grid item xs={12} md={6}>
                <TextField
                  margin="dense"
                  name="enterprise"
                  value={formik.values.enterprise}
                  label="Empresa"
                  onBlur={formik.handleBlur}
                  onChange={formik.handleChange}
                  fullWidth
                  required
                  variant="outlined"
                  error={formik.touched.enterprise && Boolean(formik.errors.enterprise)}
                  helperText={formik.touched.enterprise && formik.errors.enterprise}
                />
              </Grid>
              <Grid item xs={12} md={6}>
                <TextField
                  margin="dense"
                  name="phoneNumber"
                  label="Teléfono"
                  type="tel"
                  required
                  value={formik.values.phoneNumber}
                  onBlur={formik.handleBlur}
                  onChange={formik.handleChange}
                  fullWidth
                  variant="outlined"
                  error={formik.touched.phoneNumber && Boolean(formik.errors.phoneNumber)}
                  helperText={formik.touched.phoneNumber && formik.errors.phoneNumber}
                />
              </Grid>
              <Grid item xs={12}>
                <TextField
                  margin="dense"
                  type="email"
                  name="email"
                  label="Email"
                  required
                  value={formik.values.email}
                  onBlur={formik.handleBlur}
                  onChange={formik.handleChange}
                  fullWidth
                  variant="outlined"
                  error={formik.touched.email && Boolean(formik.errors.email)}
                  helperText={formik.touched.email && formik.errors.email}
                />
              </Grid>
              <Grid item xs={12}>
                <TextField
                  margin="dense"
                  name="comment"
                  label="Comentario (máx. 300 caracteres)"
                  value={formik.values.comment}
                  onBlur={formik.handleBlur}
                  onChange={formik.handleChange}
                  multiline
                  rows={5}
                  fullWidth
                  variant="filled"
                  error={formik.touched.comment && Boolean(formik.errors.comment)}
                  helperText={formik.touched.comment && formik.errors.comment}
                />
              </Grid>
              <Grid item style={{ display: 'flex' }} xs={12}>
                <div style={{ margin: 'auto' }}>
                  <ReCAPTCHA
                    sitekey={process.env.GATSBY_CAPTCHA_KEY ? process.env.GATSBY_CAPTCHA_KEY : ''}
                    onChange={(value: any) => {
                      setCaptchaValue(value);
                    }}
                  />
                </div>
              </Grid>
              <Grid item style={{ display: 'flex' }} xs={12}>
                <Button
                  className={classes.sendButton}
                  type="submit"
                  variant="contained"
                  color="primary"
                  startIcon={formik.isSubmitting ? <CircularProgress size="1rem" /> : null}
                  disabled={formik.isSubmitting || !captchaValue || !formik.isValid}
                >
                  Enviar
                </Button>
              </Grid>
            </Grid>
          </form>
        </DialogContent>
      </Dialog>
    </React.Fragment>
  );
};
export default formTeo;
