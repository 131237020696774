import React from 'react';
import IndexSection from './Index/Index';
import FeaturesSection from './Features/Features';
import FunctionalitiesSection from './Functionalities/Functionalities';

const API = () => {
  return (
    <div className="landing-page">
      <IndexSection />
      <FeaturesSection />
      <FunctionalitiesSection />
    </div>
  );
};

export default API;
