import { Theme, createStyles, makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {},
    container: {},
    contactButton: {
      background: '#FAB234',
      border: 0,
      textAlign: 'center',
      textTransform: 'capitalize',
      color: '#074F71',
      borderRadius: '40px',
      padding: '0px 25px',
      fontSize: '22px',
      width: 214,
      height: 59,
      fontFamily: `Montserrat`,
      fontWeight: `bold`,
      '&:hover': {
        background: '#074F71',
        color: '#FAB234',
        boxShadow: '0px 2px 10px #888888',
      },
      margin: '0 0 0 auto',
    },
    dialog: {
      '& .MuiDialog-paper': {
        padding: '40px',
      },
      '& .MuiDialogContent-root': {
        padding: '0 24px 24px 24px',
      },
      '& #form-dialog-title': {
        color: '#074F71',
      },
    },
    form: {
      '& label.Mui-focused': {
        color: '#074F71',
      },
      '& .MuiOutlinedInput-root': {
        '&:hover fieldset': {
          borderColor: '#FAB234',
        },
        '&.Mui-focused fieldset': {
          borderColor: '#FAB234',
        },
      },
      '& .MuiFilledInput-root': {
        backgroundColor: '#E5EDF1',
      },
      '& .MuiFilledInput-underline:after': {
        borderColor: '#FAB234',
      },
    },
    buttonContainer: {
      display: 'flex',
      width: '79%',
      paddingLeft: '8.5%',
    },
    closeButton: {
      position: 'absolute',
      right: theme.spacing(1),
      top: theme.spacing(1),
      color: theme.palette.grey[500],
    },
    sendButton: {
      width: '50%',
      color: '#074F71',
      backgroundColor: '#FAB234',
      margin: '0 auto',
      textTransform: 'capitalize',
      borderRadius: '40px',
      '&:hover': {
        background: '#074F71',
        color: '#FAB234',
        boxShadow: '0px 2px 10px #888888',
      },
    },
    [theme.breakpoints.down(769)]: {
      contactButton: {
        color: '#074F71',
        borderRadius: '40px',
        padding: '0px 25px',
        fontSize: '18px',
        width: '100%',
        height: '50px',
        margin: '0 auto',
      },
      buttonContainer: {
        display: 'flex',
        width: '100%',
        paddingLeft: 0,
      },
    },
    [theme.breakpoints.down(501)]: {
      dialog: {
        '& .MuiDialog-paper': {
          padding: '2%',
        },
      },
    },
  })
);
export default useStyles;
