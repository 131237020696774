import axios from 'axios';
import { initializeAxiosMockAdapter, isMockEnabled } from '../mock/mock.config';

const APISendEmail = axios.create({
  baseURL: process.env.GATSBY_SENDEMAIL_API_URL,
  headers: {
    'Content-type': 'application/json',
  },
});

if (isMockEnabled()) {
  initializeAxiosMockAdapter(APISendEmail);
}

export default APISendEmail;
