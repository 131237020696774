import React from 'react';
import useStyles from './Index.styles';
import apiIMG from '../../../../../content/assets/icons/API/API.png';
import { Container, Grid } from '@material-ui/core';
const Index = () => {
  const classes = useStyles();
  return (
    <section id="ApiSection" className={classes.root}>
      <Container className={classes.container} maxWidth="lg">
        <Grid container>
          <Grid item xs={12} md={6}>
            <span className={classes.title}>API</span>
            <h1>
              <span>La primera</span> API
              <br /> de transferencias
              <br />
              internacionales
              <br />
              en Chile
            </h1>
            <p>
              Si tu empresa tiene
              <strong> grandes volúmenes de operaciones </strong>y necesitas
              <strong> automatizar </strong>los procesos de
              <strong> pagos internacionales,</strong> contamos con una API que te ayudará a hacerlo
              de manera simple, rápida y segura.
            </p>
          </Grid>
          <Grid id="imgContainer" item xs={12} md={6}>
            <img src={apiIMG} alt="API CurrencyBird" />
          </Grid>
        </Grid>
      </Container>
    </section>
  );
};
export default Index;
