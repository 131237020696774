import React from 'react';
import useStyles from './Functionalities.styles';
import ModalContactForm from '../../../ContactForm/ContactForm';
import { Container } from '@material-ui/core';

const Functionalities = () => {
  const classes = useStyles();
  return (
    <section id="" className={classes.root}>
      <Container className={classes.container} maxWidth="lg">
        <h2>
          Con la <strong> API </strong>de CurrencyBird podrás:
        </h2>
        <div className={classes.functionalitiesContainer}>
          <p>
            1. Tener acceso a más de <strong> 70 destinos </strong>y cotizar más de{' '}
            <strong> 30 divisas. </strong>
          </p>
          <p>
            2. Crear <strong> destinatarios </strong>y<strong> realizar pagos.</strong>
          </p>
          <p>
            3. <strong> Consultar el estado </strong>de cada operación.
          </p>
          <p>
            4. Acceder a un <strong> Dashboard Web </strong>con los balances y reportes de
            operaciones, destinatarios y clientes.
          </p>
          <p>
            5. Contar con un servicio de <strong> postventa en línea </strong>
            para guiarte en caso de operaciones con contingencias.
          </p>
        </div>
        <ModalContactForm />
      </Container>
    </section>
  );
};
export default Functionalities;
