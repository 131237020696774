import { Theme, createStyles, makeStyles } from '@material-ui/core/styles';
import background from '../../../../../content/assets/icons/API/functionalitiesBackground.svg';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      padding: '122px 0',
    },
    container: {
      backgroundImage: `url(${background})`,
      backgroundPosition: 'right center',
      backgroundSize: '590px 407px',
      backgroundRepeat: 'no-repeat',
      backgroundOrigin: 'content-box',
      color: '#004F71',
      '& h2': {
        fontSize: '48px',
        fontWeight: 'normal',
        margin: '0 0 60px 0',
        textAlign: 'left',
      },
      '& p:not(:last-child)': {
        marginBottom: '20px',
      },
      '& p': {
        fontSize: '22px',
        lineHeight: '35px',
      },
      '& #conversemos': {
        fontSize: '30px',
        color: '#F8B133',
        lineHeight: '35px',
        textAlign: 'right',
        fontWeight: 'bold',
        margin: 0,
      },
    },
    functionalitiesContainer: {
      width: '79%',
      paddingLeft: '8.5%',

      margin: '0 0 20px 0',
    },
    [theme.breakpoints.down(1921)]: {
      container: {
        padding: '0 6%',
      },
    },
    [theme.breakpoints.down(1365)]: {},
    [theme.breakpoints.down(769)]: {
      root: {},
      functionalitiesContainer: {
        width: '100%',
        paddingLeft: '8.5%',
      },
    },
    [theme.breakpoints.down(501)]: {
      root: {
        padding: '80px 0',
      },
      container: {
        padding: '0 11.15%',
        backgroundSize: '442px 305px',
        backgroundPosition: 'left 148px',
        '& h2': {
          fontSize: '30px',
          marginBottom: '40px',
        },
        '& p': {
          fontSize: '18px',
          lineHeight: '22px',
        },

        '& #conversemos': {
          textAlign: 'center',
          lineHeight: '37px',
        },
      },
      functionalitiesContainer: {
        width: '85%',
        paddingLeft: 0,
        margin: '0 0 60px 0',
      },
    },
  })
);
export default useStyles;
