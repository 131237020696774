import { Theme, createStyles, makeStyles } from '@material-ui/core/styles';
const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      color: '#004F71',
      backgroundColor: '#E5EDF1',
      padding: '122px 0',
    },
    item: {
      width: '29%',
      '& h2': {
        lineHeight: '30px',
        fontSize: '30px',
        marginTop: '45px',
        fontWeight: 'bold',
      },
      '& p': {
        fontSize: '18px',
        lineHeight: '24px',
      },
      '& img': {
        height: '90px',
        width: '115px',
      },
    },
    [theme.breakpoints.down(1921)]: {
      container: {
        padding: '0 6%',
      },
    },
    [theme.breakpoints.down(769)]: {
      root: {
        padding: '122px 0 32px 0',
      },
      item: {
        margin: 'auto auto 90px auto',
        width: '90%',
      },
    },
    [theme.breakpoints.down(501)]: {
      root: {
        padding: '80px 0 12px 0',
      },
      container: {
        padding: '0 11.15%',
      },
      item: {
        width: '100%',
        margin: 'auto auto 68px auto',
        '& img': {
          width: '29.5%',
        },
        '& h2': {
          fontSize: '22px',
          marginTop: '12px',
          fontWeight: 'bold',
        },
        '& p': {
          fontSize: '16px',
          lineHeight: '19px',
        },
      },
    },
  })
);
export default useStyles;
