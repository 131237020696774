import { Theme, createStyles, makeStyles } from '@material-ui/core/styles';
const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      marginTop: '72px',
      padding: '90px 0',
      backgroundColor: '#fff',
      color: '#004F71',
      '& h1': {
        fontSize: '48px',
        fontWeight: 'normal',
        lineHeight: '58px',
        margin: 0,
        marginBottom: '45px',
        '& span': {
          color: '#F8B133',
        },
      },
      '& p': {
        margin: 0,
        fontSize: '22px',
        lineHeight: '34px',
        paddingBottom: '30px',
      },
    },
    title: {
      margin: 0,
      fontSize: '18px',
      lineHeight: '22px',
      marginBottom: '13px',
      fontWeight: 'bold',
      display: 'block',
    },
    container: {
      paddingLeft: `10% !important`,
      paddingRight: `10% !important`,
      '& #imgContainer': {
        display: 'flex',
        '& img': {
          margin: '22px 0 auto auto',
          width: 'auto',
          height: 'auto',
          maxWidth: '100%',
        },
      },
      [theme.breakpoints.up(500)]: {
        paddingLeft: `10%`,
        paddingRight: `10%`,
      },
      [theme.breakpoints.up(768)]: {
        paddingLeft: `6% !important`,
        paddingRight: `6% !important`,
      },
      [theme.breakpoints.up(1921)]: {
        paddingLeft: `0% !important`,
        paddingRight: `0% !important`,
      },
    },
    [theme.breakpoints.down(769)]: {
      root: {
        padding: '40px 0',
      },
      container: {
        '& #imgContainer': {
          display: 'flex',
          '& img': {
            margin: 'auto',
          },
        },
      },
    },
    [theme.breakpoints.down(501)]: {
      root: {
        '& h1': {
          fontSize: '30px',
          lineHeight: '35px',
          margin: 0,
          marginBottom: '15px',
          '& span': {
            color: '#F8B133',
          },
        },
        '& p': {
          margin: 0,
          fontSize: '14px',
          lineHeight: '18px',
          paddingBottom: '10px',
        },
      },
      title: {
        margin: 0,
        fontSize: '11px',
        lineHeight: '22px',
        marginBottom: '9px',
        fontWeight: 'bold',
        display: 'block',
      },
    },
  })
);
export default useStyles;
